import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
gsap.registerPlugin(ScrollTrigger);
let onethirdClass = 'gdc-one-third';

/**
 * initializeBlock
 *
 * All JS to load on the block page goes here.
 *
 * @date    15/4/19
 * @since   1.0.0
 *
 * @param   object $block The block jQuery element.
 * @param   object attributes The block attributes (only available when editing).
 * @return  void
 */
var initializeBlock = function($block, $attributes) {
	let elem = $block;
    if (!$block.hasClass(onethirdClass)) {
        elem = $block.find("." + onethirdClass);
    }

    elem.each(function() {
        let childElement = $(this);
        gsap.timeline({
            scrollTrigger: {
                trigger: childElement,
                start: "bottom 95%", // when the top of the trigger hits the top of the viewport
                // end: "bottom 60%",
                end: "top 200px",
                scrub: true,
            }
        }).to(childElement,  { x: 0 });
    });
};

// Initialize each block on page load (front end).
$(document).ready(function() {
	$("." + onethirdClass).each(function() {
		initializeBlock($(this), false);
	});
});

// Initialize dynamic block preview (editor).
if (window.acf) {
	window.acf.addAction("render_block_preview/type=accordion", initializeBlock);
}